
    import { Account } from '@/models/data/account'
    import Vue from 'vue'
    import { Component, Prop } from 'vue-property-decorator'
    import Accounts from '@/store/modules/accounts'
    import { getModule } from 'vuex-module-decorators'
    import { Action, Rule, Sorter } from '@/models/data/sorter'
    import Sorters from '@/store/modules/sorters'
    import { DataTableHeader } from 'vuetify'
    import { SorterMatchingMode } from '@/api/enums'
    import Notifications from '@/lib/notifications'
    import { Folder } from '@/models/data/folder'

    @Component
    export default class ComponentForm extends Vue {
        @Prop() sorter!: Sorter

        sortersModule = getModule(Sorters, this.$store)
        accountsModule = getModule(Accounts, this.$store)

        saving = false

        operators = [
            {
                text: 'zaczyna się od',
                value: 'starts_with'
            },
            {
                text: 'kończy się na',
                value: 'ends_with'
            },
            {
                text: 'zawiera',
                value: 'contains'
            },
            {
                text: 'nie zawiera',
                value: 'not_contain'
            },
            {
                text: '>',
                value: 'bigger_than'
            },
            {
                text: '<',
                value: 'lesser_than'
            },
            {
                text: '>=',
                value: 'bigger_or_equal'
            },
            {
                text: '<=',
                value: 'lesser_or_equal'
            },
            {
                text: '!=',
                value: 'not_equal'
            },
            {
                text: '=',
                value: 'equals'
            },
        ]

        fields = [
            {
                text: 'Nadawca',
                value: 'from'
            },
            {
                text: 'Odbiorca',
                value: 'to'
            },
            {
                text: 'Treść',
                value: 'body'
            },
            {
                text: 'Temat',
                value: 'subject'
            },
            {
                text: 'Kontrahent',
                value: 'contractor'
            }
        ]

        flags = [
            {
                text: 'Oflagowane',
                value: 'flagged',
            },
            {
                text: 'Przeczytane',
                value: 'seen'
            }
        ]

        matchingModes = [
            {
                text: 'Wszystkie',
                value: SorterMatchingMode.ALL
            },
            {
                text: 'Przynajmniej jedna',
                value: SorterMatchingMode.ONE
            }
        ]

        headers: { rules: Array<DataTableHeader>, actions: Array<DataTableHeader> } = {
            rules: [
                { text: 'Pole', value: 'field' },
                { text: 'Operator', value: 'operator' },
                { text: 'Wartość', value: 'value' },
                { text: '', value: 'actions' }
            ],
            actions: [
                { text: 'Nazwa', value: 'name' },
                { text: 'Opis', value: 'description' },
                { text: 'Konto', value: 'account' },
                { text: 'Akcje', value: 'actions' }
            ]
        }

        actions = [
            {
                text: 'Oznacz',
                value: 'mark',
                options: []
            },
            {
                text: 'Skopiuj',
                value: 'copy',
                options: []
            },
            {
                text: 'Przenieś',
                value: 'move',
                options: []
            },
            {
                text: 'Usuń',
                value: 'delete',
                options: []
            },
        ]

        get accounts (): Array<Account> {
            return this.accountsModule.entries.data
        }

        flattenFolders (folders: Array<Folder> | undefined): Array<{ path: string }> {
            let paths: Array<{ path: string }> = []
            if (folders === undefined) {
                return paths
            }
            folders.forEach(folder => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (folder.children !== null) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const morePaths = this.flattenFolders(folder.children)
                    paths = [...paths, ...morePaths]
                }
                paths.push({ path: folder.path })
            })
            return paths
        }

        get folders (): Array<{ path: string }> {
            console.log('formatting')
            const folders = this.sorter.account.folders

            if (folders !== null) {
                return this.flattenFolders(folders)
            }

            return []
        }

        addRule (): void {
            this.sorter.rules.push(new Rule())
        }

        removeRule (rule: Rule): void {
            this.sorter.rules.splice(this.sorter.rules.indexOf(rule), 1)
        }

        addAction (): void {
            this.sorter.actions.push(new Action())
        }

        removeAction (rule: Action): void {
            this.sorter.actions.splice(this.sorter.actions.indexOf(rule), 1)
        }

        async save (): Promise<void> {
            // By default, flag value is null - we have to switch it to false.
            this.sorter.actions.forEach(action => {
                if (action.name === 'mark' && action.value.flag_value === null) {
                    action.value.flag_value = false
                }
            })

            try {
                this.saving = true
                await this.sortersModule.save(this.sorter)
            } catch (e) {
                console.log(e)
                Notifications.error('Wystąpił problem w trakcie zapisywania.')
            } finally {
                Notifications.success('Zapisano pomyślnie.')
                this.saving = false
            }
        }

        async mounted (): Promise<void> {
            await this.accountsModule.loadFolders()
            console.log('loaded')
            const account = this.accounts.find(x => x.id === this.sorter.account.id)
            this.sorter.account = account ?? new Account
        }
    }
