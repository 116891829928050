
    import Vue from 'vue'
    import { Component } from 'vue-property-decorator'
    import { getModule } from 'vuex-module-decorators'
    import Sorters from '@/store/modules/sorters'
    import { Sorter } from '@/models/data/sorter'
    import { DataTableHeader } from 'vuetify'
    import Notifications from '@/lib/notifications'
    import ComponentForm from '@/components/sorters/ComponentForm.vue'
    import Mode from '@/store/mode'
    import { cloneDeep } from 'lodash'

    @Component({
        components: { ComponentForm }
    })
    export default class PageSorter extends Vue {
        sortersModule = getModule(Sorters, this.$store)

        loading = false
        dialog = {
            enabled: false,
            sorter: new Sorter(),
            mode: Mode.CREATE
        }

        headers: Array<DataTableHeader> = [
            {
                text: 'Nazwa',
                value: 'name'
            },
            {
                text: 'Opis',
                value: 'description'
            },
            {
                text: 'Konto',
                value: 'account'
            },
            {
                text: '',
                value: 'is_active'
            },
            {
                text: '',
                value: 'actions'
            }
        ]

        // region mutations
        get sorters (): Array<Sorter> {
            return cloneDeep(this.sortersModule.entries.data)
        }

        get sorter (): Sorter {
            return this.sortersModule.activeSorter
        }
        // endregion

        // region utility
        select (sorter: Sorter): void {
            this.sortersModule.setMode(Mode.EDIT)
            this.sortersModule.setSelectedSorter(sorter)
        }

        edit (sorter: Sorter): void {
            this.dialog.enabled = true
            this.dialog.sorter = cloneDeep(sorter)
            this.dialog.mode = Mode.EDIT
            this.sortersModule.setMode(Mode.EDIT)
        }

        create (): void {
            this.dialog.enabled = true
            this.dialog.sorter = new Sorter()
            this.dialog.mode = Mode.CREATE
            this.sortersModule.setMode(Mode.CREATE)

        }

        async remove (sorter: Sorter): Promise<void> {
            sorter.status.startRemoving()
            try {
                await this.sortersModule.remove(sorter)
                Notifications.success('Usunięto filtr')
                sorter.status.successRemoving()
            } catch (e) {
                // sorter.status.errorRemoving(e?.message)
                sorter.status.errorRemoving()
                Notifications.error('Nie udało się usunąć filtra.')
            }
        }
        // endregion

        // region actions
        async loadSorters (): Promise<void> {
            this.loading = true
            try {
                await this.sortersModule.loadEntries()
            } catch (e) {
                console.log(e)
                Notifications.error('Wystąpił problem w trakcie ładowania listy filtrów.')
            } finally {
                this.loading = false
            }
        }

        async run (sorter: Sorter): Promise<void> {
            sorter.status.startLoading()
            try {
                await this.sortersModule.run(sorter)
                sorter.status.successLoading()
            } catch (e) {
                sorter.status.errorLoading()
                // sorter.status.errorLoading(e?.message)
                console.log(e)
            }
        }

        async updateIsActive (sorter: Sorter): Promise<void> {
            sorter.status.startLoading()
            try {
                if (sorter.is_active) {
                    await this.sortersModule.activate(sorter)
                } else {
                    await this.sortersModule.deactivate(sorter)
                }
                sorter.status.successLoading()
            } catch (e) {
                sorter.status.errorLoading()
                // sorter.status.errorLoading(e.message)
                console.log(e)
            }
        }
        // endregion

        mounted (): void {
            this.loadSorters()
        }
    }
